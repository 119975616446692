


































































/* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapState } from 'vuex'
  import { userCanAccess, userHasRole } from '@/composables/UserGrant'

  export default defineComponent({
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: true,
      },
    },

    data () {
      return {
        mini: true,
        items: [
          {
            icon: 'mdi-calendar',
            title: this.$t('menu.calendar'),
            to: { name: 'Planning' },
          },
          {
            icon: 'mdi-steering',
            title: this.$t('menu.training_sessions'),
            to: { name: 'TrainingSession List' },
          },
          {
            icon: 'mdi-card-account-details',
            title: this.$t('menu.trainee'),
            to: { name: 'Trainee List' },
          },
          {
            icon: 'mdi-domain',
            title: this.$t('menu.company'),
            to: { name: 'Company List' },
          },
          {
            icon: 'mdi-account-plus',
            title: this.$t('menu.import'),
            to: { name: 'Trainee Import' },
            children: [
              {
                icon: 'mdi-account-cog',
                title: this.$t('menu.import_need_training'),
                to: { name: 'Trainee Import' },
                exact: true,
              },
              {
                icon: 'mdi-account-sync',
                title: this.$t('menu.import_status_need_training'),
                to: { name: 'Trainee Import Status', params: { code: 'status' } },
                exact: true,
              },
            ],
          },
          {
            icon: 'mdi-wallet-travel',
            title: this.$t('menu.trainer'),
            to: { name: 'Trainer List' },
          },
          {
            icon: 'mdi-garage-variant',
            title: this.$t('menu.car_dealer'),
            to: { name: 'CarDealer List' },
          },
          {
            icon: 'mdi-map-marker',
            title: this.$t('menu.place'),
            to: { name: 'TrainingLocation' },
          },
          {
            icon: 'mdi-car',
            title: this.$t('menu.equipment'),
            to: { name: 'Vehicle List' },
          },
          {
            icon: 'mdi-calendar-multiple-check',
            title: this.$t('menu.bookings'),
            to: { name: 'Booking List' },
          },
          {
            icon: 'mdi-cog',
            title: this.$t('menu.admin'),
            to: { name: 'UserGroup List' },
            children: [
              {
                icon: 'mdi-account-multiple',
                title: this.$t('menu.user_group'),
                to: { name: 'UserGroup List' },
              },
              {
                icon: 'mdi-account',
                title: this.$t('menu.user'),
                to: { name: 'User List' },
              },
              {
                icon: 'mdi-cog',
                title: this.$t('menu.training_type'),
                to: { name: 'TrainingType List' },
              },
              {
                icon: 'mdi-car-wrench',
                title: this.$t('menu.car_models'),
                to: { name: 'CarModel List' },
              },
              {
                icon: 'mdi-email',
                title: this.$t('menu.email'),
                to: { name: 'NotificationEmail List' },
              },
              {
                icon: 'mdi-cog',
                title: this.$t('menu.configuration'),
                to: { name: 'Configuration List' },
              },
            ],
          },
        ],
      }
    },

    computed: {
      ...mapState('theme', ['barColor', 'barImage']),
      ...mapGetters('user', {
        isUser: 'isUser',
      }),
      drawer: {
        get (): any {
          return this.$store.state.theme.drawer
        },
        set (val: any) {
          this.$store.commit('theme/setDrawer', val)
        },
      },
      computedItems (): any {
        return this.items.map(this.mapItem)
      },
    },
    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },
    methods: {
      mapItem (item: any) {
        if (item.title === this.$t('menu.equipment')) {
          if (userHasRole('ROLE_READ_TEACHING_TOOL') && !userHasRole('ROLE_READ_VEHICULE')) {
            item.to.name = 'TeachingTool List'
          }
        }
        if (!userCanAccess(item.to.name)) {
          return null
        }
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
        }
      },
    },
  })
